import React, { useState, useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl"

// s4n
// static
    // import executeQuery from "./query"
    // import CollectionDataUtils from "./../../../utils/Collection/data"

// dynamic
    import HttpClient from './../../../utils/HttpClient'
    import Preloader from './../Preloader'

import ImageS4n from './../image'
// import ImageS4n from './../Image/index'
import { ReactSlick as CarouselSlider } from './../../../hooks/drupal/parseContentProcessor/Carousel/useReactSlick'

import LogoAirbnb from './../../../images/custom/icons/airbnb.svg'
import LogoBooking from './../../../images/custom/icons/booking.svg'
import LogoTripadvisor from './../../../images/custom/icons/tripadvisor.svg'
import { kebabCase } from 'lodash';

import axios from 'axios' // jsonapi
import $ from "jquery"


const FETCH_REVIEWS_FROM_BOOKING_COM = false;


const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const source2Icon = {
        [`airbnb`]: LogoAirbnb,
        [`bookingcom`]: LogoBooking,
        [`tripadvisor`]: LogoTripadvisor,
    }

    const [state, setState] = useState({
        loading: true,
        error: false,
        fetchedData: null,
        fetchedDataFromBookingCom: null,
    });

    const fetchData = async () => {
        // Here we get reviews from Drupal backend!
        const response = await HttpClient.jsonapi(intl.locale, `node/review`, `filter[status][value]=1&sort=-created`);

        let responseBookingCom = false;
        if (FETCH_REVIEWS_FROM_BOOKING_COM) {
            // Here we get reviews from https://www.booking.com/hotelfeaturedreviews/pl/pokoje-w-starej-karczmie.pl.html
            const proxy = `https://cors-anywhere.herokuapp.com/`; // @see: https://github.com/axios/axios/issues/569#issuecomment-629650509
            const url = `https://www.booking.com/hotelfeaturedreviews/pl/pokoje-w-starej-karczmie.pl.html`; // site that doesn’t send Access-Control-*
            const options = {
                // crossDomain: true,
                // withCredentials: true,
                // headers: {
                //      Accept: "application/json, text/plain, */*"
                //     'Access-Control-Allow-Origin': '*',
                //     // 'Content-Type': 'application/json',
                //     // 'Content-Type': 'text/html',
                //     // Accept: `text/html`
                //     // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                //     // 'Access-Control-Request-Headers': `Content-Type`,
                //     // 'Access-Control-Request-Method': `GET`,
                // }
            };
            // const responseBookingCom = await axios.get(url, options);
            responseBookingCom = await axios.get(proxy + url, options);
// console.log(responseBookingCom)
// console.log(responseBookingCom.data)
        }

// console.log(response.data)

        setState((prevState, props) => ({
            ...prevState,
            loading: false,
            fetchedData: response.data,
            fetchedDataFromBookingCom: responseBookingCom || responseBookingCom.data,
        }));
    }

    // @see: https://stackoverflow.com/questions/10585029/parse-an-html-string-with-js
    const parseDataFromBookingCom = (data) => {
        let processed = [];
        const $el = $(`<ul></ul>`);
        $el.html(data);
        $(`li.review_item`, $el).each(function(index, value) {
// console.log($(value))
            // const $date = $(value).find(`.review_item_date`).text().replace(`Ocena dodana: `, ``).trim();
            const $name = $(value).find(`.reviewer_name`).text().trim();
            const $review_pos = $(value).find(`.review_pos`).text().trim();
// console.log($date, $name, $review_pos)
            
            processed = [
                ...processed,
                {
                    node: {
                        title: $name,
                        field_source: `bookingcom`,
                        body: {
                            value: ``,
                            processed: $(`<p>` + $review_pos + `</p>`).html(),
                        },
                    }
                },
            ]
        });

        return processed;
    }

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        fetchData();
    }, []);
    
    let aDataCollectionTranslated = null;

    // static
        // const data = executeQuery()
        // // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
        // aDataCollectionTranslated = CollectionDataUtils.filterData(data.index.edges, intl.locale);

    // dynamic
    if (state.fetchedData) {
        aDataCollectionTranslated = state.fetchedData.data.map(node => {
            return {node: node.attributes}
        })
// console.log(aDataCollectionTranslated)

        if (FETCH_REVIEWS_FROM_BOOKING_COM && state.fetchedDataFromBookingCom) {
            aDataCollectionTranslated = aDataCollectionTranslated.filter(({ node }) => node.field_source !== `bookingcom`);
// console.log(aDataCollectionTranslated)
            aDataCollectionTranslated = [
                ...parseDataFromBookingCom(state.fetchedDataFromBookingCom.data),
                ...aDataCollectionTranslated,
            ]
        }
    }

    const carouselSliderSettings = {
        lazyLoad: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        // adaptiveHeight: true,

        autoplay: true,
        // speed: 5000,
        autoplaySpeed: 5000,
        pauseOnHover: true,

        responsive: 
            // @see: https://getbootstrap.com/docs/4.0/layout/overview/
            [
                // up to breakpoint use certain settings
                {
                    breakpoint: 1500, // Custom non Bootstrap breakpoint
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },                                
                // {
                //     breakpoint: 1200, // Extra large devices (large desktops, 1200px and up)
                //     settings: {
                //         slidesToShow: 2,
                //         slidesToScroll: 1,
                //     }
                // },
                // {
                //     breakpoint: 992, // Large devices (desktops, 992px and up)
                //     settings: {
                //         slidesToShow: 2,
                //         slidesToScroll: 1,
                //     }
                // },
                {
                    breakpoint: 768, // Medium devices (tablets, 768px and up)
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                // {
                //     breakpoint: 576, // Small devices (landscape phones, 576px and up)
                //     settings: {
                //         slidesToShow: 1,
                //         slidesToScroll: 1,
                //     }
                // }
            ],
    };

    // @see: https://stackoverflow.com/questions/822452/strip-html-from-text-javascript
    const stripHtml = html => {
        // NOT WORKIN WITH SSR - document!
        // const tmp = document.createElement(`div`);
        // tmp.innerHTML = html;
        // return tmp.textContent || tmp.innerText || ``;

        // WORKS ALSO WITH SSR - no document!
        return html.replace(/<[^>]*>?/gm, ``);
    }

    const truncateText = text => {
        // node.body ? node.body.processed.split(' ').splice(0, 40).join(' ') + '...' : ''
        const TEXT_TRUNCATE_TO_WORDS = 30;
        const bodyProcessed = stripHtml(text);
        const aBodyProcessedWords = bodyProcessed.split(` `);

        if (aBodyProcessedWords.length > TEXT_TRUNCATE_TO_WORDS) {
            return aBodyProcessedWords.splice(0, TEXT_TRUNCATE_TO_WORDS).join(` `) + `...`;
        }

        return bodyProcessed;
    }

    return (
        <React.Fragment>
            <section 
                // id={`${kebabCase(t({ id: `soft4net_reviews` }))}`} 
                className={`section section-padding-horizontal s4n-reviews`}
            >
                <div className="container p-0">
                    <h2 
                        id={`${kebabCase(t({ id: `soft4net_reviews` }))}`} 
                        className="section-title">{ t({ id: `soft4net_reviews` }) }
                    </h2>
                    <div className="is-cropped enable-carousel arrow-outside">
                        {!state.loading ? 
                            <React.Fragment>
                                {aDataCollectionTranslated &&
                                    <CarouselSlider {...carouselSliderSettings}>
                                        {/* {items.map((review, index) => { */}
                                        {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
                                        {aDataCollectionTranslated.map(({ node }, index) => {
                                            const review = node;

                                            const bodyProcessedStripHtml = node.body ? stripHtml(node.body.processed) : null;
                                            const bodyProcessedTruncated = node.body ? truncateText(node.body.processed) : null;
                                            const isTruncated = bodyProcessedStripHtml !== bodyProcessedTruncated;

                                            return (
                                                <div className="review">
                                                    <div className="inner">
                                                        <span className="review-name">{review.title}</span>
                                                        <span className="review-description">
                                                            {isTruncated && 
                                                                <span className="text-truncated" 
                                                                    dangerouslySetInnerHTML={{ __html: bodyProcessedTruncated }}
                                                                ></span>
                                                            }                                                            
                                                            <span className="text-full" 
                                                                dangerouslySetInnerHTML={{ __html: bodyProcessedStripHtml }}
                                                            ></span>
                                                        </span>
                                                        <span className="review-source">
                                                            {/* <ImageS4n src={source2Icon[review.source]} alt={review.name} className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" /> */}
                                                            <a 
                                                                rel="nofollow" 
                                                                // href={review.url} 
                                                                target="_blank"
                                                            >
                                                                {source2Icon[review.field_source] ? 
                                                                    <img src={source2Icon[review.field_source]} alt={review.title} className="review-logo" /> : 
                                                                    <React.Fragment>
                                                                        <i className="fas fa-exclamation-circle"></i> {intl.locale === `en` ? `No source` : `Brak źródła`}
                                                                    </React.Fragment>
                                                                }
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </CarouselSlider>
                                }
                            </React.Fragment>
                            :
                            <Preloader mode="indeterminate" color={`#573516`} />
                        }
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ComponentStateless;