import { graphql } from "gatsby"
import FragmentProductRelated from '../commerceProductDefault/product-related'

export const query = graphql`
    fragment FragmentPageDetails on node__page {

        id
        drupal_id
        drupal_internal__nid
        internal {
            type
        }
        langcode
        link: path {
            url: alias
            langcode
        }
        title
        body {
            summary
            value
        }
        created
        field_summary
        field_metatag {
            # basic
                title
                description
                abstract
                keywords
            # advanced
                canonical_url
                cache_control
        }

        relationships {
            __typename



            # @todo: Extract as fragment
            field_related_products {
                ... on commerce_product__default {
                    ...FragmentProductRelated
                }
            }

            # field_paragraph {
            #   ... on paragraph__section {
            #     field_section_title
            #     field_section_header
            #     field_section_content_short {
            #       value
            #     }
            #   }
            #   ... on paragraph__paragraph_container {
            #     relationships {
            #       field_paragraph {
            #         ... on paragraph__section {
            #           field_section_title
            #           field_section_header
            #           field_section_content_short {
            #             value
            #           }
            #         }
            #         ... on paragraph__carousel_item {
            #           field_carousel_item_content {
            #             value
            #           }
            #           field_carousel_item_image_print
            #           field_carousel_item_link {
            #             uri
            #           }
            #         }
            #       }
            #     }
            #   }
            # }

        }

    }
`