import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const data = useStaticQuery(graphql`
        query {

            rooms: allNodeRoom (
                # filter: {
                #     promote: {
                #         eq: true
                #     }
                # }
                sort: {
                    fields: [created]
                    order: ASC
                }
            ) {
                edges {
                    node {
                        id
                        drupal_id
                        drupal_internal__nid
                        internal {
                            type
                        }                    

                        title
                        langcode
                        path {
                            alias
                            langcode
                        }
                        created
                        body {
                            summary
                            processed
                        }

                        relationships {
                            bundle: __typename

                            field_images {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 930) {
                                            # ...GatsbyImageSharpFluid
                                            # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                                            # 
                                            ...GatsbyImageSharpFluid_withWebp_noBase64
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }

            packages: allNodePackages (
                # filter: {
                #     promote: {
                #         eq: true
                #     }
                # }
                sort: {
                    # fields: [changed]
                    fields: [created]
                    order: ASC
                }
            ) {
                edges {
                    node {
                        id
                        drupal_id
                        drupal_internal__nid
                        internal {
                            type
                        }                    

                        title
                        langcode
                        path {
                            alias
                            langcode
                        }
                        created
                        body {
                            summary
                            processed
                        }

                        field_display_group

                        relationships {
                            bundle: __typename

                            field_images {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 930) {
                                            # ...GatsbyImageSharpFluid
                                            # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                                            # ...GatsbyImageSharpFluid_withWebp
                                            ...GatsbyImageSharpFluid_withWebp_noBase64
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }

            surroundings: allNodeLocation (
                # filter: {
                #     promote: {
                #         eq: true
                #     }
                # }
                sort: {
                    fields: [created]
                    order: ASC
                }
            ) {
                edges {
                    node {
                        id
                        drupal_id
                        drupal_internal__nid
                        internal {
                            type
                        }                    

                        title
                        langcode
                        path {
                            alias
                            langcode
                        }
                        created
                        body {
                            summary
                            processed
                        }

                        field_video_link

                        relationships {
                            bundle: __typename

                            field_images {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 930) {
                                            # ...GatsbyImageSharpFluid
                                            # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                                            # ...GatsbyImageSharpFluid_withWebp
                                            ...GatsbyImageSharpFluid_withWebp_noBase64
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    `)

    return data;
}
