// @see: https://react-slick.neostack.com/docs/example/slick-go-to
// @see: https://reactjs.org/docs/forwarding-refs.html
// @see: https://www.freakyjolly.com/react-slick-carousel-with-custom-navigation-and-lazy-loaded-images-in-slider/#.XyK4MBMzbuQ

import React, { useState, useRef, useEffect, useLayoutEffect, useContext} from 'react';
import { useIntl } from "gatsby-plugin-intl"

// s4n
// static
import executeQuery from "./query"
import CollectionDataUtils from "./../../../utils/Collection/data"

import Img from 'gatsby-image'
// import ImageS4n from './../image'
// import ImageS4n from './../Image/index'
import { ReactSlick as CarouselSlider } from './../../../hooks/drupal/parseContentProcessor/Carousel/useReactSlick'

import { kebabCase } from 'lodash';
// import GeneralUtils from './../../../utils/common'

import { connect } from 'react-redux'
import { toggleModalBooking } from './../../../s4n/state/actions/modal'
import { bindOnClickOnKeyDown } from './../Accessibility'

import { Carousel } from 'react-bootstrap'
import cn from 'classnames'
// import handleViewport from 'react-in-viewport';

import $ from "jquery"



// import ContextScrollableNodes from './../../../s4n/context/ScrollableNodes';



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { 
        type, 
        alignColText = `right`,
    } = props;

    // const contextScrollableNodes = useContext(ContextScrollableNodes);

    // const { inViewport, enterCount } = props;



    let aDataCollectionTranslated = null;

    // static
    const data = executeQuery();

    // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
    aDataCollectionTranslated = CollectionDataUtils.filterData(data[type].edges, intl.locale);
// console.log(`aDataCollectionTranslated: `, aDataCollectionTranslated);

    let aDataCollectionTranslated_Grouped = {};
    if (`packages` === type) {
        aDataCollectionTranslated.forEach((item, index) => {
// console.log(`item: `, item);
            if (`undefined` === typeof aDataCollectionTranslated_Grouped[item.node.field_display_group]) {
                aDataCollectionTranslated_Grouped[item.node.field_display_group] = [];
            }

            aDataCollectionTranslated_Grouped[item.node.field_display_group].push(item);
        });
// console.log(`aDataCollectionTranslated_Grouped: `, aDataCollectionTranslated_Grouped);
// console.log(`Object.keys(aDataCollectionTranslated_Grouped): `, Object.keys(aDataCollectionTranslated_Grouped));
    }



    // We cannot merge those states because every state update triggets render()
    const [slider, setSlider] = useState(null);
    const [sliderState, setSliderState] = useState({
        slideIndex: 0,
        updateCount: 0,
    });

    let sliderPackagesInitState = {};
    let sliderStatePackagesInitState = {};

    Object.keys(aDataCollectionTranslated_Grouped).forEach(display_group => {
        sliderPackagesInitState[display_group] = null;
        sliderStatePackagesInitState[display_group] = {
            slideIndex: 0,
            updateCount: 0,
        };
    });

    const [sliderPackages, setSliderPackages] = useState(sliderPackagesInitState);
    const [sliderStatePackages, setSliderStatePackages] = useState(sliderStatePackagesInitState);

    React.useEffect(() => {
        setSliderPackages(prevState => ({
            ...prevState,
            // 'slider-ciasteczka': SLIDERS[`slider-ciasteczka`],
            ...(Object.keys(aDataCollectionTranslated_Grouped).map(field_display_group => ({[field_display_group]: sliderPackages[field_display_group]}))),
        }))
    }, [
        // sliders,
    ]);



    const carouselSliderSettings = {
        lazyLoad: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        infinite: true,
        arrows: false,
        adaptiveHeight: true,
        dots: false,
    };

    const scrollToClass = {
        rooms: `soft4net_our_apartments`,
        packages: `soft4net_packages`,
        surroundings: `soft4net_surroundings`,
    };

    // You can now get a ref directly to the DOM button:
    // const ref = React.createRef(slider => setSlider(slider));

    const handleOpenBookingModal = (props) => {
        props.toggleModalBooking(props.isModalBookingOpened); // this modifies redux store state
    }

    useEffect(() => {
        $('.slick-hidden-button a.btn').on(`click`, function () {
            slider && slider.slickGoTo(0);
        });
    });

    // Refresh slick slider on font size change
    // if (props.fontSize) {
    //     slider && slider.slickGoTo(sliderState.slideIndex);
    // }
    useEffect(() => {
        if (slider) {
            slider.slickGoTo(sliderState.slideIndex);
        }

        Object.keys(aDataCollectionTranslated_Grouped).map(display_group => {
            if (sliderPackages[display_group]) {
                sliderPackages[display_group].slickGoTo(sliderStatePackages[display_group].slideIndex);
            }
        });        
    }, [
        props.fontSize
    ]);

    return (
        <React.Fragment>
            {/* <p>inViewport: {inViewport ? `true` : `false`}</p> */}
            <section id={`${kebabCase(t({ id: scrollToClass[type] }))}`} className={`section s4n-scrollable-nodes s4n-${type}`}>
                <div className="container p-0">
                    <div className="_is-cropped enable-carousel _arrow-outside">
                        {`packages` === type ?
                            <React.Fragment>
                                {Object.keys(aDataCollectionTranslated_Grouped).map((display_group, display_group_index) => {
// console(`display_group: `, display_group);

                                    return (
                                        <CarouselSlider 
                                            className={cn({
                                                'mb-5 pb-5': 0 === display_group_index
                                            })}
                                            // ref={ref} // Not working, why?
                                            ref={slider => {
                                                // Error: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.
                                                // setSliderPackages(prevState => ({
                                                //     ...prevState,
                                                //     [display_group]: slider,
                                                // }));

                                                // WORKS!!!
                                                sliderPackages[display_group] = slider;
                                            }}
                                            {...({
                                                ...carouselSliderSettings,
                                                afterChange: () => {
                                                    setSliderStatePackages(prevState => ({
                                                        ...prevState,
                                                        [display_group]: {
                                                            ...prevState[display_group],
                                                            updateCount: prevState.updateCount + 1,
                                                        },
                                                    }));
                                                },
                                                beforeChange: (current, next) => {
                                                    setSliderStatePackages(prevState => ({
                                                        ...prevState,
                                                        [display_group]: {
                                                            ...prevState[display_group],
                                                            slideIndex: next,
                                                        },
                                                    }));
                                                },
                                            })}
                                        >
                                            {/* {items.map((node, index) => { */}
                                            {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
                                            {aDataCollectionTranslated_Grouped[display_group].map(({ node }, index) => {
// console.log(node.relationships.field_images)
// console.log(references.slider)

                                                return (
                                                    <div key={`slide-item-${kebabCase(t({ id: scrollToClass[type] }))}-${index}`} className="row node">
                                                        {/* <div className="row node"> */}
                                                            {display_group_index % 2 === 1 ?
                                                                <div className="col-12 col-lg-6 col-text">
                                                                    <h2 className="node-name d-none d-lg-block">{node.title}</h2>
                                                                    <div className="node-description" dangerouslySetInnerHTML={{ __html: node.body ? node.body.processed : null }}></div>
                                                                    <p>{intl.locale === `en` ? `To book a package, contact us` : `W celu rezerwacji pakietu skontaktuj się z nami`}:</p>
                                                                    <a className="btn action goto-scroll mr-3" href={`#${kebabCase(t({ id: `soft4net_footer_contact_title` }))}`}><i class="fas fa-file-signature"></i>
                                                                        {intl.locale === `en` ? `Write to us` : `Napisz do nas`}
                                                                    </a>
                                                                    <a className="btn action goto-scroll" href={`tel:+48690059590`}><i class="fas fa-phone"></i>
                                                                        {intl.locale === `en` ? `Call us` : `Zadzwoń do nas`}
                                                                    </a>
                                                                </div>
                                                                : null
                                                            }
                                                            <div className="col-12 col-lg-6 col-media">
                                                                {/* <h1>{GeneralUtils.dateFormat(node.created, null, intl.locale)}</h1> */}
                                                                <h2 className="node-name d-lg-none">{node.title}</h2>
                                                                <div 
                                                                    // style={{height: `10rem`, _paddingBottom: `100%`, overflow: `hidden`,}}
                                                                >
                                                                    {/* @see: https://react-bootstrap.github.io/components/carousel/ */}
                                                                    {node.field_video_link ? 
                                                                        <React.Fragment>
                                                                            <div class="videoWrapper">
                                                                                {/* Copy & Pasted from YouTube, but remember to replace watch with embed, @see: https://stackoverflow.com/questions/51976152/refused-to-display-https-www-youtube-com-watchv-okzrsbjqjos-in-a-frame-beca */}
                                                                                <iframe width="560" height="315" src={node.field_video_link} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                            </div>
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            {node.relationships.field_images.length > 1 ? 
                                                                                <Carousel
                                                                                    fade={true}
                                                                                    controls={node.relationships.field_images.length > 1 ? true : false}
                                                                                    indicators={false}
                                                                                    interval={null}
                                                                                >
                                                                                        {node.relationships.field_images.map((image, index) => {
                                                                                            const fragmentType = `fluid`;
                                                                                            const oImage = image.localFile.childImageSharp[fragmentType];
// console.log(image)
// console.log(oImage)

                                                                                            return (
                                                                                                <Carousel.Item key={`image_carousel_item_${index}`}>
                                                                                                    {/* @see: Gatsby image not loading due to not aware screen scroll - how to solve this? */}
                                                                                                    <Img 
                                                                                                        key={index}
                                                                                                        className="node-images"
                                                                                                        fluid={oImage} 
                                                                                                        // alt={oImage.alt}
                                                                                                    />
                                                                                                </Carousel.Item>
                                                                                            );
                                                                                        })}
                                                                                </Carousel>
                                                                                :
                                                                                <Img 
                                                                                    key={0}
                                                                                    className="node-images"
                                                                                    fluid={node.relationships.field_images[0].localFile.childImageSharp[`fluid`]} 
                                                                                    // alt={oImage.alt}
                                                                                />
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                </div>

                                                                {/* Custom slder naviagtion */}
                                                                {sliderPackages[display_group] && 
                                                                    <div className="_row buttons">
                                                                        <ul>
                                                                            {aDataCollectionTranslated_Grouped[display_group]
                                                                                // .filter(({ node }, index) => 0 !== index)
                                                                                .map(({ node }, index) => {
                                                                                if (0 === index && `GROUP_1` === display_group) {
                                                                                    return null;
                                                                                }
// console.log(`node: `, node);
                                                                                const oLiClassNames = cn({
                                                                                    // 'slick-hidden-button': 0 === index && `GROUP_1` === display_group,
                                                                                    'slick-active': index === sliderStatePackages[display_group].slideIndex,
                                                                                });

                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <li className={oLiClassNames}>
                                                                                            <a className="btn" 
                                                                                                onClick={() => {
                                                                                                sliderPackages[display_group].slickGoTo(index); 
                                                                                                // slider.forceUpdate();
                                                                                            }}>{node.title}</a>
                                                                                        </li>
                                                                                        {/* {index % 3 === 0 ? <li className={`break-row`}></li> : null} */}
                                                                                    </React.Fragment>
                                                                                )                        
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                }

                                                            </div>
                                                            {display_group_index % 2 === 0 ?
                                                                <div className="col-12 col-lg-6 col-text">
                                                                    <h2 className="node-name d-none d-lg-block">{node.title}</h2>
                                                                    <div className="node-description" dangerouslySetInnerHTML={{ __html: node.body ? node.body.processed : null }}></div>
                                                                    <p>{intl.locale === `en` ? `To book a package, contact us` : `W celu rezerwacji pakietu skontaktuj się z nami`}:</p>
                                                                    <a className="btn action goto-scroll mr-3" href={`#${kebabCase(t({ id: `soft4net_footer_contact_title` }))}`}><i class="fas fa-file-signature"></i>
                                                                        {intl.locale === `en` ? `Write to us` : `Napisz do nas`}
                                                                    </a>
                                                                    <a className="btn action goto-scroll" href={`tel:+48690059590`}><i class="fas fa-phone"></i>
                                                                        {intl.locale === `en` ? `Call us` : `Zadzwoń do nas`}
                                                                    </a>
                                                                </div>
                                                                : null
                                                            }
                                                        {/* </div> */}
                                                    </div>
                                                )
                                            })}
                                        </CarouselSlider>
                                    )
                                })}
                            </React.Fragment>
                            : null
                        } 

                        {`packages` !== type && aDataCollectionTranslated.length ?
                            <CarouselSlider 
                                // ref={ref} // Not working, why?
                                ref={slider => {
                                    setSlider(slider);
                                }}
                                {...({
                                    ...carouselSliderSettings,
                                    afterChange: () => setSliderState(prevState => ({
                                        ...prevState,
                                        updateCount: prevState.updateCount + 1,
                                    })),
                                    beforeChange: (current, next) => setSliderState(prevState => ({
                                        ...prevState,
                                        slideIndex: next,
                                    })),
                                })}
                            >
                                {/* {items.map((node, index) => { */}
                                {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
                                {aDataCollectionTranslated.map(({ node }, index) => {
// console.log(node.relationships.field_images)
// console.log(references.slider)

                                    return (
                                        <div key={`slide-item-${kebabCase(t({ id: scrollToClass[type] }))}-${index}`} className="row node">
                                            {/* <div className="row node"> */}
                                                {`left` === alignColText ?
                                                    <div className="col-12 col-lg-6 col-text">
                                                        <h2 className="node-name d-none d-lg-block">{node.title}</h2>
                                                        <div className="node-description" dangerouslySetInnerHTML={{ __html: node.body ? node.body.processed : null }}></div>
                                                        {`rooms` === type &&
                                                            <a 
                                                                className="btn action"
                                                                {...bindOnClickOnKeyDown(() => handleOpenBookingModal(props))}
                                                            ><i class="fas fa-calendar-day"></i>{intl.locale === `en` ? `Book now` : `Zarezerwuj teraz`}</a>
                                                        }
                                                    </div>
                                                    : null
                                                }
                                                <div className="col-12 col-lg-6 col-media">
                                                    {/* <h1>{GeneralUtils.dateFormat(node.created, null, intl.locale)}</h1> */}
                                                    <h2 className="node-name d-lg-none">{node.title}</h2>
                                                    <div 
                                                        // style={{height: `10rem`, _paddingBottom: `100%`, overflow: `hidden`,}}
                                                    >
                                                        {/* @see: https://react-bootstrap.github.io/components/carousel/ */}
                                                        {node.field_video_link ? 
                                                            <React.Fragment>
                                                                <div class="videoWrapper">
                                                                    {/* Copy & Pasted from YouTube, but remember to replace watch with embed, @see: https://stackoverflow.com/questions/51976152/refused-to-display-https-www-youtube-com-watchv-okzrsbjqjos-in-a-frame-beca */}
                                                                    <iframe width="560" height="315" src={node.field_video_link} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                </div>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                {node.relationships.field_images.length > 1 ? 
                                                                    <Carousel
                                                                        fade={true}
                                                                        controls={node.relationships.field_images.length > 1 ? true : false}
                                                                        indicators={false}
                                                                        interval={null}
                                                                    >
                                                                            {node.relationships.field_images.map((image, index) => {
                                                                                const fragmentType = `fluid`;
                                                                                const oImage = image.localFile.childImageSharp[fragmentType];
// console.log(image)
// console.log(oImage)

                                                                                return (
                                                                                    <Carousel.Item key={`image_carousel_item_${index}`}>
                                                                                        {/* @see: Gatsby image not loading due to not aware screen scroll - how to solve this? */}
                                                                                        <Img 
                                                                                            key={index}
                                                                                            className="node-images"
                                                                                            fluid={oImage} 
                                                                                            // alt={oImage.alt}
                                                                                        />
                                                                                    </Carousel.Item>
                                                                                );
                                                                            })}
                                                                    </Carousel>
                                                                    :
                                                                    <Img 
                                                                        key={0}
                                                                        className="node-images"
                                                                        fluid={node.relationships.field_images[0].localFile.childImageSharp[`fluid`]} 
                                                                        // alt={oImage.alt}
                                                                    />
                                                                }
                                                            </React.Fragment>
                                                        }

                                                    </div>

                                                    {/* Custom slder naviagtion */}
                                                    {slider && 
                                                        <div className="_row buttons">
                                                            <ul>
                                                                {aDataCollectionTranslated.map(({ node }, index) => {
                                                                    const oLiClassNames = cn({
                                                                        'slick-hidden-button': 0 === index,
                                                                        'slick-active': index === sliderState.slideIndex,
                                                                    });

                                                                    return (
                                                                        <React.Fragment>
                                                                            <li className={oLiClassNames}><a className="btn" 
                                                                                onClick={() => {
                                                                                    slider.slickGoTo(index); 
                                                                                    // slider.forceUpdate();
                                                                                }}
                                                                            >{node.title}</a></li>
                                                                            {index === 2 ? <li className={`break-row`}></li> : null}
                                                                        </React.Fragment>
                                                                    )                        
                                                                })}
                                                            </ul>
                                                        </div>
                                                    }

                                                </div>
                                                {`right` === alignColText ?
                                                    <div className="col-12 col-lg-6 col-text">
                                                        <h2 className="node-name d-none d-lg-block">{node.title}</h2>
                                                        <div className="node-description" dangerouslySetInnerHTML={{ __html: node.body ? node.body.processed : null }}></div>
                                                        {`rooms` === type &&
                                                            <a 
                                                                className="btn action"
                                                                {...bindOnClickOnKeyDown(() => handleOpenBookingModal(props))}
                                                            ><i class="fas fa-calendar-day"></i>{intl.locale === `en` ? `Book now` : `Zarezerwuj teraz`}</a>
                                                        }
                                                    </div>
                                                    : null
                                                }
                                            {/* </div> */}
                                        </div>
                                    )
                                })}
                            </CarouselSlider>
                            : null
                        }
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isModalBookingOpened: state.modal.isModalBookingOpened,
    fontSize: state.fontSize.fontSize,
})
const mapDispatchToProps = dispatch => ({
  toggleModalBooking: isModalBookingOpened => dispatch(toggleModalBooking(!isModalBookingOpened)),
})
// export default ComponentStateless;
export default connect(mapStateToProps, mapDispatchToProps)(
    // handleViewport(
        React.memo(ComponentStateless)
    // , { rootMargin: '-1.0px' })
);