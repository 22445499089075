// import React, { useState, useEffect } from 'react';
// import { useIntl } from "gatsby-plugin-intl"

// // s4n
// import $ from 'jquery'
// import loadScript from 'simple-load-script';
// // @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
// import { withPrefix } from 'gatsby'
// import useVersion from './../../../hooks/useVersion'
// import { kebabCase } from 'lodash';



// const ComponentStateless = (props) => {
//     const intl = useIntl();
//     const t = intl.formatMessage;

//     const currentBuildVersion = useVersion();

//     /**
//      * @see: https://reactjs.org/docs/hooks-effect.html
//      * Same as componentDidMount() hook because we pass an empty array [] as second argument,
//      * this argument watches for changes in passed arguments
//      * In componentDidMount we have access to window object elsewhere not always!!!
//      */
//     useEffect(() => {
//         loadScripts();
//     }, []);
//     // }, [intl.locale]);

//     const enableGoogleMaps = (GMaps) => {
//         // @see: https://snazzymaps.com/style/21343/mr-brown
//         const styles = 
//         [
//             {
//                 "featureType": "administrative",
//                 "elementType": "geometry.fill",
//                 "stylers": [
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "administrative",
//                 "elementType": "geometry.stroke",
//                 "stylers": [
//                     {
//                         "color": "#6e3a35"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "administrative",
//                 "elementType": "labels.text.fill",
//                 "stylers": [
//                     {
//                         "color": "#6e3a35"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "administrative",
//                 "elementType": "labels.text.stroke",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "administrative.locality",
//                 "elementType": "labels.icon",
//                 "stylers": [
//                     {
//                         "color": "#6e3a35"
//                     },
//                     {
//                         "saturation": "0"
//                     },
//                     {
//                         "visibility": "on"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "landscape",
//                 "elementType": "geometry.fill",
//                 "stylers": [
//                     {
//                         "color": "#efebea"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "landscape",
//                 "elementType": "geometry.stroke",
//                 "stylers": [
//                     {
//                         "color": "#af9d94"
//                     },
//                     {
//                         "saturation": "0"
//                     },
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "landscape",
//                 "elementType": "labels.text.fill",
//                 "stylers": [
//                     {
//                         "color": "#6e3a35"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "landscape",
//                 "elementType": "labels.text.stroke",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "landscape.man_made",
//                 "elementType": "geometry.fill",
//                 "stylers": [
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "poi",
//                 "elementType": "geometry",
//                 "stylers": [
//                     {
//                         "hue": "#ff0000"
//                     },
//                     {
//                         "saturation": "34"
//                     },
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "poi",
//                 "elementType": "labels.text.fill",
//                 "stylers": [
//                     {
//                         "color": "#6e3a35"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "poi",
//                 "elementType": "labels.text.stroke",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "poi",
//                 "elementType": "labels.icon",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road",
//                 "elementType": "geometry.stroke",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road",
//                 "elementType": "labels.text.fill",
//                 "stylers": [
//                     {
//                         "color": "#6e3a35"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road",
//                 "elementType": "labels.text.stroke",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road",
//                 "elementType": "labels.icon",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     },
//                     {
//                         "lightness": "60"
//                     },
//                     {
//                         "gamma": "1.00"
//                     },
//                     {
//                         "hue": "#ff0000"
//                     },
//                     {
//                         "saturation": "-90"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road.highway",
//                 "elementType": "geometry.fill",
//                 "stylers": [
//                     {
//                         "color": "#ffffff"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "road.highway",
//                 "elementType": "labels.icon",
//                 "stylers": [
//                     {
//                         "visibility": "simplified"
//                     },
//                     {
//                         "hue": "#ff0000"
//                     },
//                     {
//                         "weight": "0.01"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "transit",
//                 "elementType": "geometry.fill",
//                 "stylers": [
//                     {
//                         "color": "#bfb1a9"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "transit",
//                 "elementType": "geometry.stroke",
//                 "stylers": [
//                     {
//                         "color": "#bfb1a9"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "transit",
//                 "elementType": "labels.text.fill",
//                 "stylers": [
//                     {
//                         "color": "#6e3a35"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "transit",
//                 "elementType": "labels.text.stroke",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "transit",
//                 "elementType": "labels.icon",
//                 "stylers": [
//                     {
//                         "visibility": "on"
//                     },
//                     {
//                         "hue": "#ff0000"
//                     },
//                     {
//                         "saturation": "-90"
//                     },
//                     {
//                         "lightness": "0"
//                     },
//                     {
//                         "gamma": "1.00"
//                     },
//                     {
//                         "weight": "1"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "water",
//                 "elementType": "all",
//                 "stylers": [
//                     {
//                         "color": "#dfd8d4"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "water",
//                 "elementType": "labels.text",
//                 "stylers": [
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "water",
//                 "elementType": "labels.text.fill",
//                 "stylers": [
//                     {
//                         "color": "#6e3a35"
//                     },
//                     {
//                         "saturation": "0"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "water",
//                 "elementType": "labels.text.stroke",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             },
//             {
//                 "featureType": "water",
//                 "elementType": "labels.icon",
//                 "stylers": [
//                     {
//                         "visibility": "off"
//                     }
//                 ]
//             }
//         ];

//         // https://www.google.com/maps/place/Cegielniana+4A,+30-404+Krak%C3%B3w/@50.0284114,19.9331043,17z/data=!4m5!3m4!1s0x47165ca3b74ab023:0x41ccdeceb39098ca!8m2!3d50.028408!4d19.935293?shorturl=1

//         const map = new GMaps({
//             div: '#map',
//             lat: Number(process.env.GOOGLE_MAPS_LAT),
//             lng: Number(process.env.GOOGLE_MAPS_LNG),
//             zoom: 14,
//             scrollwheel: false,
//             // draggable: $(document).width() > 736 ? true : false,
//         });

//         map.addStyle({
//             styledMapName: 'Styled Map',
//             styles: styles,
//             mapTypeId: 'map_style',
//         });

//         map.setStyle('map_style');

//         map.drawOverlay({
//             lat: map.getCenter().lat(),
//             lng: map.getCenter().lng(),
//             content: `<div class="overlay"><img src="/static/soft4net/image/custom/logo.svg" alt="${t({ id: `siteMetadata_title_home_page` })}" /><div class="overlay_arrow above"></div></div>`,
//             verticalAlign:'top',
//             horizontalAlign:'center',
//         });
//     }

//     const loadScripts = async () => {
//         // Google Map START
//         const GOOGLE_MAPS_KEY = `AIzaSyAw2j4v8vzGeIt0p1u5R1SwVdsNmviz1_0`;
//         await loadScript(`//maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&sensor=false`, { inBody: true });
//         await loadScript(withPrefix(`/google-map/gmaps.min.js${currentBuildVersion}`), { inBody: true });
//         // await loadScript(withPrefix('/google-map/GoogleMaps.js' + currentBuildVersion), { inBody: true });
//         // Google Map STOP
        
//         if (typeof window.GMaps !== `undefined`) {
//             enableGoogleMaps(window.GMaps);
//         }
//     }

//     return (
//         <React.Fragment>
//             <section className={`section map`}>
//                 {/* <h5 class="hide">Google Maps</h5> */}
//                 <div id={`${kebabCase(t({ id: `soft4net_location` }))}`} className={`container-fluid`}>
//                     <div className="row">
//                         <div id="map"></div>
//                     </div>
//                 </div>
//             </section>
//         </React.Fragment>
//     )
// }

// export default ComponentStateless;












// @see: https://medium.com/web-dev-survey-from-kyoto/3-gotchas-of-google-maps-api-when-used-with-next-js-and-eslint-dba627c9657d
import React, { useEffect, useRef } from 'react';
import { useIntl } from "gatsby-plugin-intl"

// s4n
// import dayjs from 'dayjs' // load on demand
import { Loader } from '@googlemaps/js-api-loader';

// import styles from './index.module.scss'
// import './index.scss'



const GOOGLE_MAPS_KEY = `AIzaSyAw2j4v8vzGeIt0p1u5R1SwVdsNmviz1_0`;
// const GOOGLE_MAPS_LAT = `49.610639`
// const GOOGLE_MAPS_LNG = `19.116683`

/*
let MAP_POI = [
    // Rozrywka i rekreacja 
        // 1. Bulwar nad Sołą   // https://www.google.com/maps/place/Bulwar+nad+So%C5%82%C4%85/@49.6056725,19.1061647,17z/data=!3m1!4b1!4m5!3m4!1s0x47142569d8ced2bf:0x5d7f34fb05fc993d!8m2!3d49.6056725!4d19.1083534?hl=PL
        { lat: 49.605815010802885, lng: 19.10836949152829, desc: `Bulwar nad Sołą` },
        // 2. Aleja Zbójników   // https://www.google.com/maps/place/Aleja+Zb%C3%B3jnik%C3%B3w/@49.6045855,19.1130747,17z/data=!3m1!4b1!4m5!3m4!1s0x4714251fab346d81:0xf9ca4a34333a9d30!8m2!3d49.6045855!4d19.1152634?hl=PL
        { lat: 49.60472106108236, lng: 19.115258033855913, desc: `Aleja Zbójników` },
        // 3. Hala widowiskowo-sportowa (ul. XX-lecia II RP 12)     // https://www.google.com/maps/place/Hala+Widowiskowo-Sportowa/@49.6043197,19.1085495,17z/data=!3m1!4b1!4m5!3m4!1s0x471425c714e49bd5:0x700977ac59dc2176!8m2!3d49.6043197!4d19.1107382?hl=PL
        { lat: 49.60430229697438, lng: 19.11130146217148, desc: `Hala widowiskowo-sportowa (ul. XX-lecia II RP 12)` },
        // 4. Amfiteatr     // https://www.google.com/maps/place/Amfiteatr/@49.6047793,19.1084308,17z/data=!3m1!4b1!4m5!3m4!1s0x4714253e253bc317:0xeb3510228b2ce530!8m2!3d49.6047793!4d19.1106195?hl=PL
        { lat: 49.60484533178232, lng: 19.110732151053476, desc: `Amfiteatr` },
        // 5. Centrum Fitness i siłownia (ul. Kościuszki 10b)   // https://www.google.com/maps/place/GET+FIT+GYM/@49.606317,19.118966,17z/data=!3m1!4b1!4m5!3m4!1s0x4714253dafe20071:0x8feafd01281200c9!8m2!3d49.606317!4d19.1211547?hl=PL
        { lat: 49.60645950891866, lng: 19.12116006269212, desc: `Centrum Fitness i siłownia (ul. Kościuszki 10b)` },

    // Miasteczko Zadrzewie
        { lat: 49.603556, lng: 19.127139, desc: `Miasteczko Zadrzewie`, iconUrl: `/images/custom/logo.svg` },
    ]
*/



// const styles = [
//     {
//         stylers: [
//             {hue: '#00ffe6'},
//             {saturation: -100},
//         ]
//     },
//     {
//         featureType: 'road',
//         elementType: 'geometry',
//         stylers: [
//             {lightness: -8},
//             {gamma: 1.18},
//             {visibility: 'simplified'},
//         ]
//     }
// ];
const styles = [
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.icon",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#efebea"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#af9d94"
            },
            {
                "saturation": "0"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#ff0000"
            },
            {
                "saturation": "34"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6e3a35"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "lightness": "60"
            },
            {
                "gamma": "1.00"
            },
            {
                "hue": "#ff0000"
            },
            {
                "saturation": "-90"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#ff0000"
            },
            {
                "weight": "0.01"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#bfb1a9"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#bfb1a9"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#ff0000"
            },
            {
                "saturation": "-90"
            },
            {
                "lightness": "0"
            },
            {
                "gamma": "1.00"
            },
            {
                "weight": "1"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#dfd8d4"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
];



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const googlemap = useRef(null);

    let MAP_POI = [
        { 
            lat: Number(process.env.GOOGLE_MAPS_LAT),
            lng: Number(process.env.GOOGLE_MAPS_LNG),
            desc: t({ id: `siteMetadata_title_home_page` }),
            iconUrl: `/static/soft4net/image/custom/logo.svg`,
        }
    ];

    if (props?.poi) {
        props.poi.forEach((poi, index) => {
            if (!poi?.lat || !poi?.lng) {
                return;
            }

            MAP_POI.push({ 
                lat: poi?.lat || null,
                lng: poi?.lng || null,
                desc: `
                    ${poi?.name ? `<strong class="h6">${poi.name}</strong>` : ``}
                    ${poi?.centrala ? `<p class="mb-0">${poi.centrala}</p>` : ``}
                    ${poi?.oddzial ? `<p class="mb-0">${poi.oddzial}</p>` : ``}
                    ${poi?.phone ? `<p class="mb-0">${poi.phone}</p>` : ``}
                ` || ``,
            });
        })
    }
// console.log(MAP_POI)
    // const currentBuildVersion = useVersion();
    // const currentBuildVersion = `?ts=${dayjs().unix()}`; // in seconds

    useEffect(() => {
        const loader = new Loader({
            apiKey: GOOGLE_MAPS_KEY,
            version: 'weekly',
        });

        // @see: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
        const mapOptions = {
            // zoom: document.documentElement.clientWidth >= 768 ? 15 : 14, // Since we set map.fitBounds(bounds); we don't need zoom it will be set automatically?
            // zoom: 16,
            zoom: 16,

            // center: {
            //     lat: Number(process.env.GOOGLE_MAPS_LAT),
            //     lng: Number(process.env.GOOGLE_MAPS_LNG),
            // },

            // mapTypeId: "map_style", // satellite

            // lat: Number(GOOGLE_MAPS_LAT),
            // lng: Number(GOOGLE_MAPS_LNG),
            
            // scrollwheel: false,
            // draggable: document.documentElement.clientWidth >= 768 ? true : false,
            // gestureHandling: ``,

            styles, // @see: https://developers.google.com/maps/documentation/javascript/styling
            // drawOverlay: {
            //     lat: map?.getCenter().lat(),
            //     lng: map?.getCenter().lng(),
            //     content: '<div class="overlay"><img src="/images/custom/logo.svg" alt="MIasteczko Zadrzewie" /><div class="overlay_arrow above"></div></div>',
            //     verticalAlign:'top',
            //     horizontalAlign:'center',
            // }
        }

        let map; 
        loader.load().then(() => {
// console.log(`enableGoogleMaps`)
            const google = window.google;
            map = new google.maps.Map(googlemap.current, mapOptions);

            // @see: https://stackoverflow.com/questions/2989858/google-maps-v3-enforcing-min-zoom-level-when-using-fitbounds
            google.maps.event.addListenerOnce(map, 'bounds_changed', function() { this.setZoom(Math.min(16, this.getZoom())); });

            if (!MAP_POI?.length) {
                return false;
            }

// console.log(MAP_POI)
            // @see: https://developers.google.com/maps/documentation/javascript/markers
            MAP_POI.map(poi => {
                let marker = new google.maps.Marker({
                    position:{
                        lat: Number(poi.lat), 
                        lng: Number(poi.lng)
                    }, // Brooklyn Coordinates
                    map: map, //Map that we need to add
                    
                    // icon: '/s4n/images/map-pin.svg',
                    icon: poi.iconUrl ? {
                        url: poi.iconUrl, // url
                        scaledSize: new google.maps.Size(50, 50), // scaled size
                        // origin: new google.maps.Point(0,0), // origin
                        // anchor: new google.maps.Point(0, 0) // anchor
                    } : null,

                    // icon: '/images/custom/map-marker.png',
                    // icon: <i class="fas fa-map-marker-alt"></i>,
                    // adding custom icons (Here I used a Flash logo marker)
                    draggarble: false, // If set to true you can drag the marker
                    // width: `1rem`,
                 });    
                 
                let information = new google.maps.InfoWindow({
                    content: poi.desc
                });
                
                marker.addListener('click', function() {
                    information.open(map, marker);
                });
            })



            // @see: https://stackoverflow.com/questions/19304574/center-set-zoom-of-map-to-cover-all-visible-markers
            let bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < MAP_POI.length; i++) {
                bounds.extend(MAP_POI[i]); // bounds.extend({lat: 123, lng: 456})
            }

            map.fitBounds(bounds);

            var zoom = map.getZoom();
            map.setZoom(zoom > 6 ? 6 : zoom);
        })
        .catch(e => {
            // do something
            console.log(e);
        });
    }, [
        props.poi
    ]);

    const anchorNameInLocale = {pl: `lokalizacja`, en: `location`}[intl.locale];

    return (
        <React.Fragment>
            <div id={anchorNameInLocale}>
                <div id="map" ref={googlemap}></div>
            </div>
        </React.Fragment>
    )
}

export default ComponentStateless;