import React from "react"
import loadable from '@loadable/component'

// import LogoCmsDrupal from './../../../../../images/custom/page/products/soft4cms/engines/drupal.png'
// import LogoCmsGatsby from './../../../../../images/custom/page/products/soft4cms/engines/gatsbyjs-logo.svg'
// import LogoCmsJoomla from './../../../../../images/custom/page/products/soft4cms/engines/Joomla.png'
// import LogoCmsDjango from './../../../../../images/custom/page/products/soft4cms/engines/django-logo-positive.svg'
// import LogoCmsWordpress from './../../../../../images/custom/page/products/soft4cms/engines/WordPress-logotype-standard.png'

// import LogoShopDrupalCommerce from './../../../../../images/custom/page/products/soft4shop/engines/drupal-commerce.svg'
// import LogoShopMagento from './../../../../../images/custom/page/products/soft4shop/engines/magento.svg'
// import LogoShopShopware from './../../../../../images/custom/page/products/soft4shop/engines/shopware.svg'
// import LogoShopPrestashop from './../../../../../images/custom/page/products/soft4shop/engines/prestashop.svg'

// import EditorGutenbergWpBlockGallery from './../../../../../components/s4n/Editor/Gutenberg/WpBlockGallery'
import ScrollableNodes from './../../../../../components/s4n/ScrollableNodes'
import Reviews from './../../../../../components/s4n/Reviews'
import GoogleMap from './../../../../../components/s4n/GoogleMap'


export default (node) => {
    const nid = typeof node.drupal_internal__nid !== `undefined` ? node.drupal_internal__nid : null;

    // const ProductRelated = loadable(() => import(`./../../../../../components/s4n/Shop/Product/related`));
    // const Subpages = loadable(() => import(`./../../../../../components/s4n/Menu/Subpages`));
    // const GoogleMap = loadable(() => import(`./../../../../../components/s4n/GoogleMap`));
    // const Portfolio = loadable(() => import(`./../../../../../components/s4n/Portfolio`));
  
    let oReplacement = {
        // [`[s4n_related_products]`]: <ProductRelated products={node.relationships && node.relationships.field_related_products ? node.relationships.field_related_products : []} />,
        // [`[s4n_subpages]`]: <Subpages nid={nid} />,
        // [`[s4n_subpages_service]`]: <Subpages nid={14} template={`service`} />,
        // [`[s4n_portfolio]`]: <Portfolio header={true} limit={6} />,
        // [`dynamic-component-path=<Team propName="propValue" />`]: true,
        [`[s4n_rooms]`]: <ScrollableNodes type="rooms" alignColText="left" />,
        [`[s4n_packages]`]: <ScrollableNodes type="packages" />,
        [`[s4n_surroundings]`]: <ScrollableNodes type="surroundings" alignColText="right" />,
        [`[s4n_reviews]`]: <Reviews />,
        [`[s4n_service_googlemaps]`]: <GoogleMap />,
        // [`[s4n_service_googlemaps lat="11.11" lng="22.22"]`]: <GoogleMap />,
    }
  
    // if (14 === nid) { // Service
    //   oReplacement[`[s4n_subpages]`] = <Subpages nid={nid} template={`service`} disableHeader={true} />
    // }
    // if (17 === nid) { // Portfolio
    //   oReplacement[`[s4n_portfolio]`] = <Portfolio />
    // }

    return oReplacement;
}